
















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { deleteAoiMesById } from '@/services/systemApi';
import { deepClone } from '@/utils/index';

@Component({
  components: {},
})
export default class System extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: {},
  })
  content!: '';
  modal = false;
  modal1 = false;
  modal2 = false;
  regionType = '1';
  markersArray: any = '';
  operateCode = '';
  labelCancel: any = '';
  refreshType = 0;
  districtInfo: any = [
    {
      name: '区域名称:',
      val: 'aoi_name',
      value: '222',
    },
    {
      name: '区域名id:',
      val: 'aoi_id',
      value: '',
    },
    {
      name: '区域面积:',
      val: 'aoi_area',
      value: '2',
    },
    {
      name: '区域类型:',
      val: 'num_type',
      value: '',
    },
    {
      name: '所属MALL项目:',
      val: 'city',
      value: '',
    },
    {
      name: '所属企业服务:',
      val: 'service_name',
      value: '',
    },
    {
      name: '申请用户:',
      val: 'user',
      value: '',
    },
    {
      name: '申请用户手机号:',
      val: 'tel',
      value: '',
    },
    {
      name: '申请时间:',
      val: 'create_time',
      value: '',
    },
    {
      name: '区域状态:',
      val: 'status',
      value: '',
    },
  ];
  modalShow(): void {
    this.modal2 = false;
    this.$emit('goBack', false);
  }
  refreshOperate(val: number): void {
    this.operateCode = '1';
    this.refreshType = val;
    this.modal1 = true;
  }
  async refreshAndGoBack(): Promise<void> {
    const id = 'aoi_id';
    const type = 'num_type';
    const res = await deleteAoiMesById({
      [id]: (this.content as any).aoi_id,
      [type]: parseInt(this.regionType, 10),
      status: this.operateCode
        ? parseInt(this.operateCode, 10)
        : parseInt((this.content as any).status, 10),
    });
    if (!res.status) {
      this.modal2 = true;
      this.$emit('refreshPage');
    }
    if (this.markersArray) {
      this.markersArray.setMap(null);
    }
    if (this.labelCancel) {
      this.labelCancel.setMap(null);
    }
    this.operateCode = '';
  }
  returnHomePage(): void {
    if (this.operateCode === '-1') {
      this.refreshAndGoBack();
    } else {
      this.$emit('goBack', false);
      if (this.markersArray) {
        this.markersArray.setMap(null);
      }
      if (this.labelCancel) {
        this.labelCancel.setMap(null);
      }
    }
  }
  cancelOperate(val: number): void {
    this.operateCode = val === 1 ? '' : '-1';
    this.modal = true;
  }
  mounted(): void {
    if (this.markersArray) {
      this.markersArray.setMap(null);
    }
    if (this.labelCancel) {
      this.labelCancel.setMap(null);
    }
    const center = new (window as any).qq.maps.LatLng(
      (this.content as any).centre_ll.split(',')[0],
      (this.content as any).centre_ll.split(',')[1],
    );
    const map = new (window as any).qq.maps.Map(
      document.getElementById('container'),
      {
        center,
        zoom: 16,
        disableDefaultUI: true,
      },
    );
    const polygon = new (window as any).qq.maps.Polygon({
      map,
    });
    // 设置多边形路径
    const path: any = [];
    (this.content as any).boundary.split('|').forEach((i: any) => {
      if (i) {
        path.push(new (window as any).qq.maps.LatLng(i.split(',')[0], i.split(',')[1]));
      }
    });
    polygon.setPath(path);
    this.markersArray = new (window as any).qq.maps.Marker({
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      icon: require('../../../../assets/basics/aoicommuntiy.png'),
      position: center,
      map,
    });
    this.labelCancel = new (window as any).qq.maps.Label({
      map,
      position: center,
      content: (this.content as any).aoi_name,
    });
    this.labelCancel.setStyle({
      color: 'rgb(69, 131, 255)',
      border: 'none',
      backgroundColor: 'transparent',
      fontSize: '20px',
      fontWeight: '500',
      transform: 'translateX(-53%)',
    });

    // 点击信息已经携带过来了
    this.regionType = (this.content as any).num_type.toString();
    this.districtInfo.forEach((i: any) => {
      if (this.content[i.val]) {
        Object.assign(i, this.defineProperty(i, 'value', this.content[i.val]));
      }
      if (i.val === 'city') {
        // eslint-disable-next-line no-param-reassign
        i.value += ` ${(this.content as any).district} ${
          (this.content as any).mall_name
        }`;
      }
    });
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  defineProperty(obj: any, key: any, val: any): any {
    // 返回object对象
    const newObj = deepClone(obj);
    return Object.defineProperty(newObj, key, {
      value: val,
      writable: true,
      configurable: true,
      enumerable: true,
    });
  }
}
