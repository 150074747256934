var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!this.showPage)?_c('div',{staticClass:"system"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"left"},[_c('div',[_c('Select',{staticStyle:{"width":"200px"},model:{value:(_vm.model1),callback:function ($$v) {_vm.model1=$$v},expression:"model1"}},_vm._l((_vm.cityList),function(item){return _c('Option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('div',[_c('Input',{staticStyle:{"width":"300px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"value"}})],1),_c('div',[_c('div',{staticClass:"search"},[_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.searchContent}},[_vm._v("查询")])],1),_c('div',[_c('Button',{attrs:{"type":"error"},on:{"click":_vm.reset}},[_vm._v("重置")])],1)])]),_c('div',{staticClass:"right"},[_c('div',[_vm._v("区域状态:")]),_c('div',[_c('Select',{staticStyle:{"width":"200px"},model:{value:(_vm.model2),callback:function ($$v) {_vm.model2=$$v},expression:"model2"}},_vm._l((_vm.districtStatus),function(item){return _c('Option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)])]),_c('div',{staticClass:"content"},[_c('Table',{attrs:{"border":"","columns":_vm.columns1,"data":_vm.data1,"loading":_vm.loading},scopedSlots:_vm._u([{key:"num_type",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.num_type === 1 ? '居住地' : row.num_type === 2 ? '工作地' : '过路地'))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.status === 1 ? '已通过' : row.status === 2 ? '待审核' : '已驳回'))])]}},{key:"operate",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('a',{staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.showContent(row)}}},[_vm._v("查看")]),_c('a',{staticClass:"red",on:{"click":function($event){return _vm.deleteContent(row, index)}}},[_vm._v("删除")])]}}],null,false,1207916255)})],1),_c('div',{staticClass:"footer"},[_c('Page',{attrs:{"total":_vm.total,"current":_vm.current,"show-total":""},on:{"on-change":_vm.pageNumChange}})],1),_c('Modal',{attrs:{"title":"系统审核"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('p',[_vm._v("请选择搜索类型。")]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.modalShow}},[_vm._v("确定")])],1)]),_c('Modal',{attrs:{"title":"系统审核"},model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[_c('p',[_vm._v("请输入搜索内容。")]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.modalShow}},[_vm._v("确定")])],1)]),_c('Modal',{attrs:{"title":"系统审核"},model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[_c('p',[_vm._v("您已删除本条客流来源区域信息。")]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.modalShow}},[_vm._v("确定")])],1)]),_c('Modal',{attrs:{"title":"系统审核"},on:{"on-ok":_vm.ok},model:{value:(_vm.modal3),callback:function ($$v) {_vm.modal3=$$v},expression:"modal3"}},[_c('p',[_vm._v("您确定要删除这条客流来源区域信息吗？")])])],1):_c('div',[_c('show-page',{attrs:{"content":_vm.content},on:{"refreshPage":_vm.refreshPage,"goBack":_vm.goBack}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }