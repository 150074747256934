











































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import showPage from './showPage/index.vue';
import { getAoiRegionSvc, deleteAoiMesById } from '@/services/systemApi';

@Component({
  components: {
    showPage,
  },
})
export default class System extends Vue {
  total = 0;
  loading = true;
  modal2 = false;
  modal3 = false;
  cityList = [
    {
      label: '区域ID',
      value: '1',
    },
    {
      label: '区域名称',
      value: '2',
    },
    {
      label: '区域类型',
      value: '3',
    },
    {
      label: '城市',
      value: '4',
    },
    {
      label: '区县',
      value: '5',
    },
    {
      label: '所属MALL项目',
      value: '6',
    },
    {
      label: '所属企业服务',
      value: '7',
    },
    {
      label: '申请用户',
      value: '8',
    },
    {
      label: '申请用户手机号',
      value: '9',
    },
  ];
  content = '';
  modal = false;
  modal1 = false;
  value = '';
  current = 1;
  model1 = '0';
  model2 = '3';
  showPage = false;
  typeTextObj: any = {
    居住地: '1',
    工作地: '2',
    过路地: '3',
  };
  districtStatus = [
    {
      value: '3',
      label: '全部',
    },
    {
      value: '1',
      label: '已通过',
    },
    {
      value: '2',
      label: '待审核',
    },
    {
      value: '-1',
      label: '已驳回',
    },
  ];

  columns1 = [
    {
      title: '区域ID',
      key: 'aoi_id',
      align: 'center',
    },
    {
      title: '区域名称',
      key: 'aoi_name',
      align: 'center',
      tooltip: true,
    },
    {
      title: '区域类型',
      key: 'num_type',
      align: 'center',
      slot: 'num_type',
    },
    {
      title: '城市',
      key: 'city',
      align: 'center',
    },
    {
      title: '区县',
      key: 'district',
      align: 'center',
    },
    {
      title: '所属MALL项目',
      key: 'mall_name',
      align: 'center',
      tooltip: true,
    },
    {
      title: '所属企业服务',
      key: 'service_name',
      align: 'center',
      tooltip: true,
    },
    {
      title: '申请用户',
      key: 'user',
      align: 'center',
    },
    {
      title: '申请用户手机号',
      key: 'tel',
      align: 'center',
      tooltip: true,
    },
    {
      title: '申请时间',
      key: 'create_time',
      align: 'center',
      tooltip: true,
      width: 200,
    },
    {
      title: '区域状态',
      key: 'status',
      align: 'center',
      slot: 'status',
    },
    {
      title: '操作',
      key: 'operate',
      slot: 'operate',
      align: 'center',
    },
  ];
  delContent: any = {};
  data1: any = [];
  @Watch('model2', { immediate: false, deep: true })
  selectValueChange(): void {
    this.loading = true;
    this.current = 1;
    this.displayPage(this.current);
  }
  mounted(): void {
    this.displayPage(this.current);
  }
  refreshPage(): void {
    this.displayPage(this.current);
  }
  modalShow(): void {
    this.modal = false;
    this.modal1 = false;
    this.modal2 = false;
  }
  async displayPage(page: number): Promise<void> {
    const type = 'search_type';
    const str = 'search_str';
    this.value = this.value.trim();
    let { value } = this;
    if (parseInt(this.model1, 10) === 3) {
      value = this.typeTextObj[this.value] || this.value;
    }
    const res = await getAoiRegionSvc({
      page,
      rows: 10,
      [type]: parseInt(this.model1, 10),
      [str]: value,
      status: parseInt(this.model2, 10),
    });
    if ((res as any).status === 0 || (res as any).status === 2) {
      this.total = Number((res as any).total);
      this.data1 = (res as any).data;
      this.loading = false;
    }
    // return res;
  }
  goBack(val: boolean): void {
    this.showPage = val;
  }
  reset(): void {
    this.model1 = '0';
    this.model2 = '3';
    this.value = '';
    this.current = 1;
    this.displayPage(this.current);
  }
  searchContent(): void {
    if (!this.model1) {
      this.modal = true;
    } else if (!this.value) {
      this.modal1 = true;
    } else if (this.value && this.model1) {
      this.current = 1;
      this.displayPage(this.current);
    }
  }
  pageNumChange(val: number): void {
    this.loading = true;
    this.current = val;
    this.displayPage(val);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  showContent(val: any): void {
    this.showPage = true;
    this.content = val;
    console.log(this.content);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async deleteContent(val: any): Promise<void> {
    this.modal3 = true;
    this.delContent = val;
  }
  async ok(): Promise<void> {
    const id = 'aoi_id';
    const type = 'num_type';
    const res = await deleteAoiMesById({
      [id]: this.delContent.aoi_id,
      [type]: this.delContent.num_type,
      status: 0,
    });
    if (!res.status) {
      this.modal2 = true;
      this.loading = true;
      this.displayPage(this.current);
    }
  }
}
